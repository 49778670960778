<!--
 * @Description: 
 * @Author: leimin
 * @Date: 2022-06-17 09:42:34
 * @LastEditTime: 2023-04-02 13:48:04
 * @LastEditors: leimin
-->
<template>
    <div class="detail-wap" v-if="data">
        <div class="video" id="J_prismPlayer">
            <video controls class="vi" webkit-playsinline playsinline x5-playsinline x-webkit-airplay="allow" :src="data.contents[indexes].oss_url" ref="video"></video>
            <p class="poster" @click="play" v-if="!isPlay"></p>
            <p class="button" @click="play" v-if="!isPlay"><i></i></p>
        </div>
        <div class="infor">
            <h2 class="title">{{ data.contents[indexes].title }}</h2>
            <p class="cont">
                <span @click="tap(index)" :class="{ cur: index == indexes }" v-for="(item, index) in data.contents" :key="item.id">{{ item.title }}</span>
                <!-- <span v-for="item in 10"> 一个人</span> -->
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: null,
            indexes: 0,
            isPlay: false
        }
    },
    methods: {
        getData(id) {
            this.$api.GetPages(id).then(res => {
                console.log(res)
                this.data = res.data
                this.$nextTick(() => {
                    this.initVideo()
                })
            })
        },
        tap(index) {
            this.isPlay=false
            this.indexes = index
        },
        play(){
            this.isPlay=true;
            this.$refs.video.play()
        },
        initVideo() {
            this.isPlay=false
        }
    },
    created() {
        this.getData(this.$route.params.id)
    }
}
</script>

<style scoped lang="scss">
.detail-wap {
    height: 100%;
    display: flex;
    flex-direction: column;
    .video {
        height: 30%;
        
        background: #a0a0a0;
        position: relative;
        .vi {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
        .poster {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: url("../../assets/images/bg.jpg") no-repeat center center;
            background-size: cover;

            z-index: 1;
        }
        .button {
            position: absolute;
            top: 15px;
            left: 15px;
            height:3em;
            padding-top:0.8em;
            line-height: 3.6em;
            width: 7.6em;
            border: 0.06666em solid #fff;
            background-color: rgba(43, 51, 63, 0.7);
            border-radius: 1em;
            z-index: 10;
            text-align: center;
            & i {
                display: inline-block;
                width: 0;
                height: 0;
                
                border-left: 1em solid #fff;
                border-top: 1em solid transparent;
                border-bottom: 1em solid transparent;
            }
        }
    }
    .infor {
        padding: 20px;
        flex: 1;
    position: relative;
        .title {
            font-size: 25px;
            border-left: 4px solid red;
            padding-left: 10px;
            margin-bottom: 20px;
        }
        .cont {
            margin-left: -15px;
    position: absolute;
    top: 68px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    overflow: auto;
            span {
                display: inline-block;
                margin: 0px 0px 15px 15px;
                border: 1px solid rgb(183, 183, 183);
                font-size: 26px;
                color: rgb(183, 183, 183);
                border-radius: 5px;
                padding: 10px 15px;
                &.cur {
                    color: red;
                }
            }
        }
    }
}
</style>
